.comparison {
    max-width: 876px;
    margin-top: 30px;
  }
  
  .comparison__caption {
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    line-height: 19px;
    color: #000000;
    padding-left: 1px;
  }
  
  .comparison__table {
    width: 100%;
    font-size: 16px;
    border: 1px solid #888888;
    border-collapse: collapse;
    border-bottom: 0px;
    margin-top: 15px;
  }
  
  .comparison__table th {
    font-weight: 700;
    font-style: normal;
    text-align: center;
    padding: 10px;
    border: 1px solid #888888;
  }
  
  .comparison__row td {
    padding: 10px;
    font-weight: 400;
    font-style: normal;
    font-family: sans-serif;
    border-right: 1px solid #888888;
    border-left: 1px solid #888888;
    border-bottom: 0px;
    border-collapse: collapse;
  }
  
  .comparison__tbody .comparison__row:last-child {
    border-bottom: 1px solid #888888;
  }
  
  .comparison__tbody .comparison__row:hover {
    background: #f2f2f2;
  }