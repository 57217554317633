.main-misc-memory__default-volume {
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  color: #000000;
  line-height: 19px;
  margin: 30px 0 15px 0;
}
  
  .main-misc-memory__check-volume {
    display: flex;
    gap: 10px;
    padding-left: 0px;
    margin: 0;
  }
  
  .main-misc-memory__volume-value {
    user-select: none;
    list-style-type: none;
  }

  .main-misc-memory__volume-value :hover{
    cursor: pointer;
  }

  #body-color {
    user-select: none;
  }
  
  .main-misc-memory__btn {
    position: relative;
  }

  .main-misc-memory__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
  }

  .main-misc-memory__input:checked + .main-misc-memory__body {
    border: 1px solid #f36223;
    outline: 1px solid #f36223;
  }

  .main-misc-memory__body {
    box-sizing: border-box;
      width: 92px;
      height: 39px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #888888;
      border-radius: 4px;
  }
  .main-misc-memory__body:hover {
    background: #f2f2f2;
    border-color: #888888;
  }
  .main-misc-memory__body:active {
    border: 1px solid #a93a09;
    outline: 1px solid #a93a09;
    cursor: pointer;
  }


  @media (max-width: 725px) {
    .main-misc-memory__default-volume {
      font-size: 14px;
      line-height: 17px;
      margin: 20px 0 15px 0;
    }
  }
  